.CompactCard {
  display: flex;
  width:20rem !important;
  height: 9rem !important;
  /* height: fit-content !important; */
  border-radius: 0.7rem;
  color: white;
  padding: 1rem;
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 5px;
  /* flex-wrap: wrap; */
}

@media screen and (min-width: 1900px) {
  .CompactCard {
    width: 22rem !important;
    height: 10rem !important;
  
  }
}

.parentContainer{
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 5px;

}

.parentContainerProd{
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 2px;

}

@media screen and (min-width: 1900px) {
  .CompactCard {
    width: 100%;
    height: auto;
    display: grid;
  grid-template-columns: repeat(3, minmax(500px, 1fr));
  gap: 2px;
  }
}

.parentContainerCharges{
  width: 90%;
  height: auto;
  display: grid;
  grid-template-columns:auto auto;
  gap: 10px;
  margin-top: 20px;
}

.CompactCard:hover {
  box-shadow: none !important;
}

/* radial bar */
.radialBar {
  flex: 1 1;
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  gap: 1rem;
}
.CircularProgressbar {
  width: 4rem !important;
  overflow: visible;
}
.CircularProgressbar-path {
  stroke: white !important;
  stroke-width: 12px !important;
  filter: drop-shadow(2px 4px 6px white);
}
.CircularProgressbar-trail {
  display: none;
}
.CircularProgressbar-text {
  fill: white !important;
}

.radialBar > span {
  font-size: 12px;
  font-weight: bold;
}

/* sideDetail*/
.detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* justify-content: space-between; */
}

/* .detail > span:nth-child(2) {
  font-size: 14px;
  font-weight: bold;
}
.detail > span:nth-child(3) {
  font-size: 12px;
} */
.title{
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 12px;
}
.small-data{
 display: flex;
 flex-direction: column;

}
.paid_value{
  display: flex;
  padding-bottom: 5px;
  font-size: 12px;
  
}
/* ExpandedCard */
.ExpandedCard {
  position: absolute;
  width: 80%;
  height: 70vh;
  z-index: 9;
  left: 16rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  top:10;
}
.ExpandedCard > span:nth-of-type(1) {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-shadow: 0px 0px 15px white;
}
.ExpandedCard > span:nth-of-type(2) {
  color: rgb(236, 236, 236);
  font-size: 15px;
}

.chartContainer {
  width: 80%;
  height: 800px;
}

@media screen and (max-width: 1200px) {
  .ExpandedCard {
    top: 2rem;
    height: 45vh;
    left: 6rem;
  }
}

@media screen and (max-width: 768px) {
  .ExpandedCard {
    top: 8rem;
    height: 50%;
    left: 25px;
    width: 80%;
  }
}
